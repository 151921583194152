@import "swiper/css/navigation";
@import "swiper/css/pagination";

/* Responsive styles for mobile */
@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important; /* Hide on small screens */
  }
}

/* Styles for screens larger than 768px */
@media screen and (min-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 32px !important;
    height: 32px !important;
    color: var(--color-bg) !important;
    /* Add any additional styling for large screen sizes here */
  }
}
